import { Navbar, Nav, Accordion, Card } from "react-bootstrap"
import React, { Component } from "react"
import logo from "../../static/images/del-navbar.png"
import { graphql, Link, StaticQuery } from "gatsby"
import bunty from "../../static/images/bunty.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AnchorLink } from "gatsby-plugin-anchor-links"

class Header extends Component {
  state = {
    mobileOpen: false,
    openMenus: [],
  }

  toggleMenu = () => {
    this.setState({
      mobileOpen: !this.state.mobileOpen,
    })
  }

  burgerClass() {
    if (this.state.mobileOpen) {
      return "hamburger animate active"
    } else {
      return "hamburger animate collapsed"
    }
  }

  menuClass() {
    if (this.state.mobileOpen) {
      return "mobile-menu open"
    } else {
      return "mobile-menu closed"
    }
  }

  makeNode(x) {
    return {
      slug: x.fields.slug,
      title: x.frontmatter.title,
      parent: x.frontmatter.parent,
      children: this.props.categories
        .filter(y => y.frontmatter.parent === x.frontmatter.title)
        .map(z => this.makeNode(z))
        .sort((a, b) => (a.position > b.position ? 1 : -1)),
      position: x.frontmatter.position,
    }
  }

  render() {
    let category_tree = []
    this.props.categories
      .filter(x => x.frontmatter.parent === null)
      .sort((a, b) => (a.position > b.position ? 1 : -1))
      .map(x => category_tree.push(x))
    category_tree = category_tree
      .map(x => {
        return this.makeNode(x)
      })
      .sort((a, b) => (a.position > b.position ? 1 : -1))

    //category_tree = category_tree.map(x => this.makeNode(x))
    const first_dropdown = []
    const first_mobile_dropdown = []

    const mobileSubnav = cat => {
      const items = []
      cat.children.forEach((x, i) => {
        if (x.children.length === 0) {
          items.push(
            <Nav.Item key={"mobile-subnav-" + i}>
              <Nav.Link href={x.slug} className="nav-link">
                {x.title}
              </Nav.Link>
            </Nav.Item>
          )
        } else {
          items.push(
            <Accordion key={"mobile-subnav-" + i}>
              <Nav.Item>
                <Accordion.Collapse eventKey={"second-category-" + i}>
                  {mobileSubnav(x)}
                </Accordion.Collapse>
                <Accordion.Toggle
                  eventKey={"second-category-" + i}
                  as={Card.Header}
                  className="nav-link"
                >
                  {x.title}
                </Accordion.Toggle>
              </Nav.Item>
            </Accordion>
          )
        }
      })
      if (items.length > 0) {
        return <div>{items}</div>
      } else {
        return ""
      }
    }

    const sideNav = cat => {
      const items = []
      cat.children.forEach((x, i) => {
        if (x.children.length === 0) {
          items.push(
            <li key={"header-" + i}>
              <Nav.Link href={x.slug}>{x.title}</Nav.Link>
            </li>
          )
        } else {
          items.push(
            <li className={"with-sidenav"} key={"header-" + i}>
              <Nav.Link href={x.slug}>
                <span>{x.title}</span>
                <FontAwesomeIcon
                  icon={["fas", "angle-right"]}
                  className={"mr-1"}
                />
              </Nav.Link>
              {sideNav(x)}
            </li>
          )
        }
      })
      if (items.length > 0) {
        return <ul>{items}</ul>
      } else {
        return ""
      }
    }

    category_tree.forEach((x, i) => {
      first_dropdown.push(
        <li key={"first-dropdown-" + i} className={"with-sidenav"}>
          <Nav.Link
            href={x.slug.indexOf("other") > -1 ? "#" : x.slug}
            className={"d-flex justify-content-between align-items-center"}
          >
            <span>{x.title}</span>
            <FontAwesomeIcon icon={["fas", "angle-right"]} className={"mr-1"} />
          </Nav.Link>
          {sideNav(x)}
        </li>
      )
    })

    category_tree.forEach((x, i) => {
      first_mobile_dropdown.push(
        <Accordion key={"mobile-dropdown-" + i}>
          <Nav.Item>
            <Accordion.Collapse eventKey={"first-category-" + i}>
              <Card.Body>{mobileSubnav(x)}</Card.Body>
            </Accordion.Collapse>
            <Accordion.Toggle
              eventKey={"first-category-" + i}
              as={Card.Header}
              className="nav-link"
            >
              {x.title}
            </Accordion.Toggle>
          </Nav.Item>
        </Accordion>
      )
    })

    return (
      <Navbar sticky="top" bg="dark" variant="dark" expand="lg">
        <div className={"container h-100"}>
          <Link to="/" className={"navbar-brand"}>
            <img src={logo} alt="Deliciae Cakes by Bunty Mahajan" />
          </Link>
          <div className="right-nav d-block d-lg-none">
            <Navbar.Toggle onClick={this.toggleMenu}>
              <button className={this.burgerClass()}>
                <span></span>
              </button>
            </Navbar.Toggle>
          </div>
          <Navbar.Collapse>
            <Nav className="d-flex d-md-none">
              <Accordion>
                <Nav.Item className={"mobile-collapsible"}>
                  <Accordion.Collapse eventKey="custom-cakes">
                    <Card.Body>{first_mobile_dropdown}</Card.Body>
                  </Accordion.Collapse>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="custom-cakes"
                    className="nav-link"
                  >
                    CUSTOM CAKES
                  </Accordion.Toggle>
                </Nav.Item>
              </Accordion>
              <Accordion>
                <Nav.Item className={"mobile-collapsible"}>
                  <Accordion.Collapse eventKey="order-online">
                    <Card.Body>
                      <Nav.Item>
                        <Nav.Link
                          href="https://orders.delcakes.in"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Gift a Cake
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          href="https://orders.delcakes.in"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Order a Dessert
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          href="https://orders.delcakes.in"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Menu
                        </Nav.Link>
                      </Nav.Item>
                    </Card.Body>
                  </Accordion.Collapse>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="order-online"
                    className="nav-link"
                  >
                    ORDER ONLINE
                  </Accordion.Toggle>
                </Nav.Item>
              </Accordion>
              <Accordion>
                <Nav.Item className={"mobile-collapsible"}>
                  <Accordion.Collapse eventKey="gifting">
                    <Card.Body>
                      <Nav.Item>
                        <Nav.Link
                          href="https://orders.delcakes.in"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Send a Gift
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Link className="nav-link" to="/corporate-gifting">
                          Custom Gifting
                        </Link>
                      </Nav.Item>
                    </Card.Body>
                  </Accordion.Collapse>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="gifting"
                    className="nav-link"
                  >
                    Gifting
                  </Accordion.Toggle>
                </Nav.Item>
              </Accordion>
              <Accordion>
                <Nav.Item className={"mobile-collapsible"}>
                  <Accordion.Collapse eventKey="why-deliciae">
                    <Card.Body>
                      <Nav.Item>
                        <AnchorLink
                          to="/why-deliciae#hall-of-fame"
                          title="Hall of Fame"
                          className="nav-link"
                        />
                      </Nav.Item>
                      <Nav.Item>
                        <AnchorLink
                          to="/why-deliciae#testimonials"
                          title="Testimonials"
                          className="nav-link"
                        />
                      </Nav.Item>
                      <Nav.Item>
                        <AnchorLink
                          to="/why-deliciae#faqs"
                          title="FAQs"
                          className="nav-link"
                        />
                      </Nav.Item>
                    </Card.Body>
                  </Accordion.Collapse>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="why-deliciae"
                    className="nav-link"
                  >
                    Why Deliciae
                  </Accordion.Toggle>
                </Nav.Item>
              </Accordion>
            </Nav>
            <Nav className="mx-auto d-none d-md-flex">
              <Nav.Item className={"with-dropdown"}>
                <Link to="/custom-cakes" className={"nav-link"}>
                  CUSTOM CAKES
                </Link>
                <div className={"navbar-dropdown"}>{first_dropdown}</div>
              </Nav.Item>
              <Nav.Item className={"with-dropdown"}>
                <Nav.Link href="/online-order">
                  ORDER ONLINE
                </Nav.Link>
                <div className={"navbar-dropdown"}>
                  <li>
                    <Nav.Link
                      href="https://orders.delcakes.in"
                      target="_blank"
                      rel="noopener"
                      className={"with-submenu"}
                    >
                      Gift &amp; Buy Cakes
                    </Nav.Link>
                  </li>
                  <li>
                    <Nav.Link
                      href="https://orders.delcakes.in"
                      target="_blank"
                      rel="noopener"
                      className={"with-submenu"}
                    >
                      Order Dessert
                    </Nav.Link>
                  </li>
                </div>
              </Nav.Item>
              <Nav.Item className={"with-dropdown"}>
                <Nav.Link>GIFTING</Nav.Link>
                <div className={"navbar-dropdown"}>
                  <li>
                    <Link to="/gifting" className={"with-submenu nav-link"}>
                      Send a Gift
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/corporate-gifting"
                      target="_blank"
                      rel="noopener"
                      className={"with-submenu nav-link"}
                    >
                      Custom Gifting
                    </Link>
                  </li>
                </div>
              </Nav.Item>
              <Nav.Item className={"with-dropdown"}>
                <Link to="/why-deliciae" className={"nav-link"}>
                  WHY DELICIAE
                </Link>
                <div className={"navbar-dropdown"}>
                  <li>
                    <AnchorLink
                      to="/why-deliciae#hall-of-fame"
                      title="Hall of Fame"
                      className="nav-link"
                    />
                  </li>
                  <li>
                    <AnchorLink
                      to="/why-deliciae#testimonials"
                      title="Testimonials"
                      className="nav-link"
                    />
                  </li>
                  <li>
                    <AnchorLink
                      to="/why-deliciae#faqs"
                      title="FAQs"
                      className="nav-link"
                    />
                  </li>
                </div>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
          <div className={"navbar-brand d-lg-block d-none"}>
            <img src={bunty} alt="Bunty Mahajan" />
          </div>
        </div>
      </Navbar>
    )
  }
}

const HeaderComponent = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "category-page" } } }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                parent
                position
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Header categories={data.allMarkdownRemark.edges.map(x => x.node)} />
    )}
  />
)

export default HeaderComponent